

import { computed, reactive, Ref, ref, toRefs } from 'vue';

// components
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
        IonGrid, IonRow, IonSpinner, IonButtons, IonButton, IonIcon, IonBackButton,
        IonItem, IonLabel, IonList, IonListHeader, IonThumbnail, IonText,
        IonInput, IonSelect, IonSelectOption, IonTextarea, IonRadioGroup, IonRadio, IonNote,
        loadingController, isPlatform, } from '@ionic/vue';
import { ImageTypesEnum, SaveOutputsEnum } from '@/utils';
import { ISignaturePad } from '@/types';
import SignaturePad from 'vue3-signature-pad';

// icons
import { location, close, cardOutline, logoPaypal, logoAlipay, logoWechat, businessOutline, refresh } from 'ionicons/icons';

// services
import BookingService from '@/services/BookingService';

import { loadStripe } from '@stripe/stripe-js';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import config from '@/config';
import { utils } from '@/composables/utils';

export default {
  name: 'CheckoutPage',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
                IonGrid, IonRow, IonSpinner, IonButtons, IonButton, IonIcon, IonBackButton,
                IonItem, IonLabel, IonList, IonListHeader, IonThumbnail, IonText,
                IonInput, IonSelect, IonSelectOption, IonTextarea, 
                IonRadioGroup, IonRadio, IonNote, SignaturePad, },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const { presentAlert, numberWithCommas, isBookingPendingDeposit, downloadBookingFile } = utils();
    const router = useRouter();
    const currId: any = router.currentRoute.value.query.bookingId;

    const downloading = ref(false);
    const showFrame = ref(false);
    const loading = computed(() => store.state.loadingBookings);
    const booking = computed(() => store.getters.getBookingById(currId));
    const order = reactive({
      signatureDataURL: null,
      paymentType: "final_payment", // default final_payment, may be changed when submitting order
      paymentMethod: "card",
      stripeToken: null,
    });
    const state = reactive({
      customStyle: { border: "black 2px solid" },
      saveType: ImageTypesEnum.PNG,
      saveOutput: SaveOutputsEnum.DATA_URL,
    });

    const signaturePad: Ref<ISignaturePad | null> = ref(null);

    const paymentMethods = [
      { key: "Credit Card", value: "card", icon: cardOutline },
      //{ key: "PayPal", value: "paypal", icon: logoPaypal },
      //{ key: "AlipayHK", value: "alipayhk", icon: logoAlipay },
      //{ key: "WeChat Pay HK", value: "wechatpayhk", icon: logoWechat },
      //{ key: "Bank Transfer", value: "bank", icon: businessOutline },
    ];
    setTimeout(() => {
      showFrame.value = true; // prevent iframe not init properly
    }, 1000);

    const inviInput = ref<any>(null); // FOR FIXING IOS KEYBOARD PROBLEM
    const stripe = ref<any>(null);
    const card = ref<any>(null);
    loadStripe(config.Stripe.publishableKey).then((res: any) => {
      stripe.value = res;
      const elements = res.elements();
      const style = {
        base: {
          color: '#32325d',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      };
      // Create an instance of the card Element.
      card.value = elements.create('card', { hidePostalCode: true, style });

      // Add an instance of the card Element into the `card-element` <div>.
      card.value.mount('#card-element');

      // Handle real-time validation errors from the card Element.
      card.value.on('change', (event: any) => {
        const displayError: any = document.getElementById('card-errors');
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = '';
        }
      });

      if (isPlatform('ios')) {
        card.value.on('blur', (e: any) => {
          inviInput.value.focus();
          setTimeout(() => {
            inviInput.value.blur();
          });
        })
      }
    })

    // methods
    const submitOrder = async (booking: any, order: any) => {
      if (isBookingPendingDeposit(booking)) {
        if (order.signatureDataURL == null) {
          presentAlert(t('signInsideBlackBox'));
          return false;
        }
        order.paymentType = 'deposit';
        order.amount = booking.calculatedDeposit;
      } else {
        order.amount = booking.calculatedFinalPayment;
      }
      const loading = await loadingController.create({});
      await loading.present();
      
      if (order.paymentMethod == 'card') {
        const result = await stripe.value.createToken(card.value);
        if (result.error) {
          // Inform the user if there was an error.
          const errorElement: any = document.getElementById('card-errors');
          errorElement.textContent = result.error.message;
          loading.dismiss();
          return false;
        } else {
          order.stripeToken = result.token.id; // pass the token to server for creating a payment
        }
      }
      const res = await BookingService.settlePayment(booking, order);
      if (res.charge && res.charge.status == "succeeded") {
        store.dispatch('getUserBookings');
        router.replace({ name: 'ThankYouPage', params: { bookingId: booking.id, paymentType: order.paymentType } });
      } else {
        presentAlert(res.error ? res.error.raw.message : t('unknownErrorOccurred'));
      }
      loading.dismiss();
    }
    const showBookingQuotation = async (booking: any) => {
      downloading.value = true;
      await downloadBookingFile(booking, 'quotation');
      downloading.value = false;
    }

    // Signature pad
    function getSignaturePad(): ISignaturePad {
      if (!signaturePad.value) throw new Error("No signature pad ref could be found");
      return signaturePad.value;
    }
    const clearSignature = () => { getSignaturePad().clearSignature() }
    const onInput = (value: any) => { order.signatureDataURL = !value ? null : value }
    
    return {
      // icons
      location, close, refresh,
      
      // variables
      loading, booking, order, submitOrder,
      downloading, showFrame,
      paymentMethods, inviInput,
      ...toRefs(state), signaturePad,

      // methods
      t, numberWithCommas,
      clearSignature, onInput,
      isBookingPendingDeposit, showBookingQuotation,
      
    }
  },
}
